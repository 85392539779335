<template>
  <el-row class="table">
    <span class="title">直播间管理</span>
    <el-row class="row-bg">
      <el-input v-model="title" placeholder="请输入直播间名称" class="tb-sm-input" @keyup.enter.native="search"></el-input>
      <date-picker ref="dataPicker" :dateTime="time" @updateView="datetTimeGet"></date-picker>
      <el-select v-model="type" placeholder="请选择" class="tb-smselect">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" @click="search" size="small" class="tb-button"
        >查 询</el-button
      >
      <el-button style="margin-left: 8px" @click="reset" size="small" class="tb-button"
        >重 置</el-button
      >
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list mt-15"
    >
      <el-table-column label="直播间名称" align="center" prop="title"></el-table-column>
      <el-table-column label="直播时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.start_time | formatDateStart }} {{scope.row.end_time | formatDateEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column label="直播间类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 4">小班课</span>
          <span v-if="scope.row.type === 2">大班课</span>
        </template>
      </el-table-column>
      <el-table-column label="班型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 4">1V{{scope.row.max_users}}</span>
          <span v-if="scope.row.type === 2">--</span>
        </template>
      </el-table-column>
      <el-table-column label="直播间渠道" align="center" prop="source"></el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import { getLiveRoomByClass } from '@/api/senate/class'
import datePicker from '@/components/date-picker'

export default {
  components: {datePicker},
  data() {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      title: '',
      time: [],
      minStartTime: '',
      maxStartTime: '',
      type: '',
      options: [{
        value: 2,
        label: '大班课'
      }, {
        value: 4,
        label: '小班课'
      }]
    }
  },
  created() {
    this.getLiveRoomByClass()
  },
  methods: {
    datetTimeGet(data) {
      if(data === null) {
        this.minStartTime = ''
        this.maxStartTime =  ''
      }else {
        this.minStartTime = data[0]
        this.maxStartTime =  data[1]
      }

    },

    async getLiveRoomByClass() {
      const res = await getLiveRoomByClass({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount:true,
        title: this.title,
        minTime: this.minStartTime,
        maxTime: this.maxStartTime,
        type: this.type
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getLiveRoomByClass()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getLiveRoomByClass()
    },

    search() {
      this.pagination.currentPage = 1
      this.getLiveRoomByClass()
    },

    reset () {
      this.title = ''
      this.time = []
      this.type = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
